import { findContextLinkWithIdFromArray } from '@bbx/common/types/contextLinks'
import { TrendCarouselItem } from '@bbx/common/types/trendCarousel'
import { getTrendCarousel } from '@bbx/search-journey/common/api/trendCarouselApiClient'
import { Box } from '@wh-components/core/Box/Box'
import { Heading } from '@wh-components/core/Heading/Heading'
import { SkeletonLine } from '@wh/common/chapter/components/Skeletons/Skeletons'
import { useOptimizelyTrack } from '@wh/common/chapter/hooks/optimizely'
import { useIsInView } from '@wh/common/chapter/hooks/useIsInView'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingActionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import dynamic from 'next/dynamic'
import React, { FunctionComponent, useContext, useEffect, useRef, useState } from 'react'
import { ImageSrcSetGalleryData } from '../ImageGallery/ImageGallery.settings'
import { VanillaImageGalleryProps } from '../ImageGallery/VanillaImageGallery/VanillaImageGallery'
import { useAbortController } from '@wh/common/chapter/api/useAbortController'

interface Props {
    title: string
    taggingData?: TaggingData
    taggingEvent?: TaggingActionEvent
    categoryId?: string
}

const VanillaImageGallery = dynamic<VanillaImageGalleryProps>(
    import('@bbx/search-journey/common/components/ImageGallery/VanillaImageGallery/VanillaImageGallery').then(
        (exports) => exports.VanillaImageGallery,
    ),
    { loading: () => <TrendCarouselSkeleton />, ssr: true },
)

export const TrendCarousel: FunctionComponent<Props> = ({ title, taggingData, taggingEvent = 'vertical_home_trend_click', categoryId }) => {
    const [trendCarouselItems, setTrendCarouselItems] = useState<TrendCarouselItem[] | undefined>(undefined)
    const [apiError, setApiError] = useState(false)
    const ref = useRef<HTMLDivElement>(null)
    const [isInView] = useIsInView(ref, '100px')

    const advertisingState = useContext(AdvertisingStateContext)
    const titleColor = advertisingState.pageModifications.foregroundColors?.['startpage-title-color']

    const { createAbortSignal, abort } = useAbortController()

    useEffect(() => {
        let unmounted = false

        async function loadTrendCarousel() {
            const result = await getTrendCarousel(categoryId, createAbortSignal()).catch(() => {
                setApiError(true)
                return undefined
            })

            if (unmounted) {
                return
            }

            setTrendCarouselItems(result?.items)
        }

        if (isInView) {
            loadTrendCarousel()
        }

        return () => {
            unmounted = true
            abort()
        }
    }, [abort, categoryId, createAbortSignal, isInView])

    const trackEvent = useOptimizelyTrack()

    const handleLinkClick = (image: ImageSrcSetGalleryData) => {
        const trendCarouselImage = image as ImageSrcSetGalleryData<TrendCarouselItem>
        trackEvent('trendwidget_interaction')
        callActionEvent(taggingEvent, taggingData, { trend_label: trendCarouselImage.additionalData?.taggingId ?? '' })
    }

    return !apiError && (trendCarouselItems === undefined || trendCarouselItems.length > 0) ? (
        <Box testId="trend-carousel-wrapper" marginBottom="l">
            <Heading level={2} fontSize="l" text={title} marginBottom="s" ref={ref} color={titleColor} />
            {trendCarouselItems && isInView ? (
                <VanillaImageGallery
                    imageData={trendCarouselItems.map(mapToImageData)}
                    eventHandlers={{ onClick: handleLinkClick }}
                    imageHeight={{ phone: '192px' }}
                    desktopNrOfShownImages={3}
                    showCounter={false}
                    slideInterval={4000}
                />
            ) : (
                <TrendCarouselSkeleton />
            )}
        </Box>
    ) : null
}

const TrendCarouselSkeleton = () => (
    <Box height={192} display="flex" justifyContent="center" testId="trend-carousel-skeleton" gap="s">
        <Box width={{ phone: 300, tablet: '100%' }}>
            <SkeletonLine />
        </Box>
        <Box display={{ phone: 'none', tablet: 'block' }} width="100%">
            <SkeletonLine />
        </Box>
        <Box display={{ phone: 'none', tablet: 'block' }} width="100%">
            <SkeletonLine />
        </Box>
    </Box>
)

const baseImageWidth = 300
const baseImageHeight = 188

const mapToImageData = (item: TrendCarouselItem): ImageSrcSetGalleryData<TrendCarouselItem> => {
    return {
        alt: item.storyblokImage.alt,
        imageUrl: {
            x1: `${item.storyblokImage.url}/m/${Math.round(baseImageWidth * 1.5)}x${Math.round(baseImageHeight * 1.5)}/filters:quality(85)`,
            x2: `${item.storyblokImage.url}/m/${baseImageWidth * 3}x${baseImageHeight * 3}/filters:quality(85)`,
        },
        link: findContextLinkWithIdFromArray('searchWebLink', item.contextLinkList)?.relativePath ?? '',
        additionalData: item,
    }
}
