import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { removeUrlParameter } from '@wh/common/chapter/lib/urlHelpers'
import { buildSeoUrl } from '@bbx/search-journey/common/lib/getSeoUrl'
import { findContextLinkWithId } from '@bbx/common/types/contextLinks'

export const getResultListSeoNavigatorLinkWithoutSfIdParam = (searchResult: SearchResult) =>
    removeUrlParameter(
        buildSeoUrl(findContextLinkWithId('resultListSeoNavigatorLink', searchResult.searchContextLinks)?.relativePath),
        'sfId',
    )
