import { useEffect, useState } from 'react'
import { storageAvailable } from '@wh/common/chapter/lib/storageAvailable'
import { OPEN_IMAGE_SEARCH_STORAGE_KEY } from '@wh/common/chapter/lib/localStorage'

export const useOpenImageSearch = () => {
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    useEffect(() => {
        if (storageAvailable('localStorage')) {
            const openImageSearchModal = localStorage.getItem(OPEN_IMAGE_SEARCH_STORAGE_KEY)
            if (openImageSearchModal) {
                localStorage.removeItem(OPEN_IMAGE_SEARCH_STORAGE_KEY)
                setModalOpen(true)
            }
        }
    }, [])
    return { modalOpen, setModalOpen }
}
