import React, { FunctionComponent } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { AzaWidget } from '@bbx/search-journey/sub-domains/search/components/common/start-pages/ContentArea/AzaWidget'
import { LazyPicture } from '@wh/common/chapter/components/LazyPicture/LazyPicture'
import BapStartAza from '@bbx/static_hashed/img/bap/startpage/bap_start_aza.png'
import BapStartAzaX2 from '@bbx/static_hashed/img/bap/startpage/bap_start_aza@2x.png'
import { TaggingActionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'

interface AzaRowProps {
    azaUrl: string
    taggingData: TaggingData
    taggingAction: TaggingActionEvent
}

const image = (
    <Box display={{ phone: 'none', tablet: 'flex' }} paddingLeft="l" maxHeight="98px" alignItems="flex-end" alignSelf="flex-end">
        <LazyPicture
            imgSrcSet={{
                x1: BapStartAza,
                x2: BapStartAzaX2,
            }}
            width="141px"
            height="112px"
        />
    </Box>
)

export const AzaRow: FunctionComponent<AzaRowProps> = ({ azaUrl, taggingData, taggingAction }) => (
    <Box display="flex" flexDirection={{ phone: 'column-reverse', tablet: 'row' }} marginBottom={{ phone: 'm', tablet: 'l' }}>
        <AzaWidget
            heading="Einfach und schnell verkaufen"
            text="Jetzt PayLivery inkl. sicherer Onlinebezahlung und Premium-Versandservice nutzen."
            azaUrl={azaUrl}
            image={image}
            taggingAction={taggingAction}
            taggingData={taggingData}
        />
    </Box>
)
