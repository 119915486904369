import { findContextLinkWithId } from '@bbx/common/types/contextLinks'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { ImageSearchModalProps } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/start-browse-pages/ImageSearch/ImageSearchModal'
import { getNavigatorById } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'
import { Box } from '@wh-components/core/Box/Box'
import ImageSearch from '@wh-components/icons/ImageSearch'
import { BbxRouter } from '@wh/common/chapter/lib/routing/bbxRouter'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingActionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'
import { concatPathWithQueryParams } from '@wh/common/chapter/lib/urlHelpers'
import dynamic from 'next/dynamic'
import React, { FunctionComponent } from 'react'
import { AutocompleteKeywordNavigator } from '../../result-list/Navigators/AutocompleteKeywordNavigator'
import { useOpenImageSearch } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/start-browse-pages/lib/useOpenImageSearch'

import { getResultListSeoNavigatorLinkWithoutSfIdParam } from '@bbx/search-journey/sub-domains/search/lib/getResultListSeoNavigatorLinkWithoutSfIdParam'

export interface SearchBoxProps {
    currentSearchResult: SearchResult
    isSearchInProgress: boolean
    autocompleteSearchTaggingEvent: TaggingActionEvent
    autocompleteAutocompleteTaggingEvent: TaggingActionEvent
    autocompleteLastSearchTaggingEvent: TaggingActionEvent
}

export const SearchBox: FunctionComponent<SearchBoxProps> = ({
    currentSearchResult,
    isSearchInProgress,
    autocompleteSearchTaggingEvent,
    autocompleteAutocompleteTaggingEvent,
    autocompleteLastSearchTaggingEvent,
}) => {
    const { modalOpen, setModalOpen } = useOpenImageSearch()

    return (
        <Box
            padding="m"
            paddingVertical={{ tablet: 'l' }}
            paddingRight={{ tablet: 0 }}
            borderRadius={{ tablet: 'm' }}
            data-search-in-progress={isSearchInProgress}
            testId="search-box-wrapper"
        >
            {modalOpen && <ImageSearchModal modalOpen={modalOpen} closeModal={() => setModalOpen(false)} />}
            <AutocompleteKeywordNavigator
                navigator={getNavigatorById(currentSearchResult, 'keyword')}
                onSearch={(_1, additionalParams, _2) => {
                    const searchSeoUrl = getResultListSeoNavigatorLinkWithoutSfIdParam(currentSearchResult)
                    const concatenatedPath = concatPathWithQueryParams(searchSeoUrl, {
                        srcType: 'vertical-search-box',
                        ...additionalParams,
                    })

                    BbxRouter.push({
                        href: concatenatedPath,
                        clientSideRouting: true,
                    })
                    return Promise.resolve()
                }}
                onClear={() => Promise.resolve()}
                taggingData={currentSearchResult.taggingData}
                showLabel={false}
                placeholder="Was suchst du?"
                autocompleteContextLink={findContextLinkWithId('autocomplete', currentSearchResult.autocompleteLinkList)}
                searchTaggingEvent={autocompleteSearchTaggingEvent}
                autocompleteTaggingEvent={autocompleteAutocompleteTaggingEvent}
                lastSearchTaggingEvent={autocompleteLastSearchTaggingEvent}
                secondaryIcon={ImageSearch}
                secondaryIconAriaLabel="Bildersuche"
                onSecondaryIconClick={() => {
                    setModalOpen(true)
                    callActionEvent('vertical_home_image_search_icon', currentSearchResult.taggingData)
                }}
            />
        </Box>
    )
}

const ImageSearchModal = dynamic<ImageSearchModalProps>(
    () =>
        import('@bbx/search-journey/sub-domains/search/components/verticals/bap/start-browse-pages/ImageSearch/ImageSearchModal').then(
            (m) => m.ImageSearchModal,
        ),
    {
        ssr: false,
    },
)
